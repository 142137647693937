<template>
<div>
	<b-card>
		<h5>Mes factures</h5>
		<vue-good-table
		  :columns="columns"
		  :rows="rows"
		  :pagination-options="{
			enabled: true,
			perPage:pageLength
		  }"
		>
		  <template
			slot="table-row"
			slot-scope="props"
		  >
			<span
			  v-if="props.column.field === 'C_num'"
			  class="text-nowrap"
			>
				<b-button
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				variant="outline-primary"
				size="sm"
				@click="openurl(props.row.C_urlpdf)"
				>
					Voir
				</b-button>
			  <span> {{ props.row.C_num }} </span>
				
			</span>
			<span v-else>
			  {{ props.formattedRow[props.column.field] }}
			</span>
		  </template>

		  <!-- pagination -->
		  <template
			slot="pagination-bottom"
			slot-scope="props"
		  >
			<div class="d-flex justify-content-between flex-wrap">
			  <div class="d-flex align-items-center mb-0 mt-1">
				<span class="text-nowrap">
				  Ligne 1 à
				</span>
				<b-form-select
				  v-model="pageLength"
				  :options="['5','10','50','100']"
				  class="mx-1"
				  @input="(value)=>props.perPageChanged({currentPerPage:value})"
				/>
				<span class="text-nowrap"> sur {{ props.total }} lignes </span>
			  </div>
			  <div>
				<b-pagination
				  :value="1"
				  :total-rows="props.total"
				  :per-page="pageLength"
				  first-number
				  last-number
				  align="right"
				  prev-class="prev-item"
				  next-class="next-item"
				  class="mt-1 mb-0"
				  @input="(value)=>props.pageChanged({currentPage:value})"
				>
				  <template #prev-text>
					<feather-icon
					  icon="ChevronLeftIcon"
					  size="18"
					/>
				  </template>
				  <template #next-text>
					<feather-icon
					  icon="ChevronRightIcon"
					  size="18"
					/>
				  </template>
				</b-pagination>
			  </div>
			</div>
		  </template>
		</vue-good-table>
	</b-card>
</div>
</template>

<script>
import {
  BCard, BPagination, BFormSelect, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
	
export default {
	components: {
		BCard,
		BPagination,
		BFormSelect,
		BButton,
		VueGoodTable,
	},
  directives: {
    Ripple,
  },
	data() {
		return {
			pageLength: 5,
			columns: [
				{
				  label: 'N°',
				  field: 'C_num',
				},
				{
				  label: 'Date',
				  field: 'Ddate',
				},
				{
				  label: 'Montant',
				  field: 'I_totalttc',
				},
				{
				  label: 'Reste',
				  field: 'I_reste',
				},
			],
			rows: [],
			selctrow: [],
		}
	},
	created() {
		this.userData = JSON.parse(localStorage.getItem('userData'))
		this.$http.get('/tb/lstfacture/71B9008B-2212-518F-6DFE-FA2337C29999')
			.then(response => { this.rows = response.data.ResultFactures })
	},
	methods: {
		openurl: function (url) {   
		  window.open(url, '_blank')
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
